<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal(false)"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Detalhes da visita') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>
        <b-overlay
          :show="processingAjax"
          rounded="sm"
        >
          <vue-perfect-scrollbar
            class="b-sidebar-scrollarea-form"
            :settings="configSettingsScroll"
          >
            <b-container
              class="pt-2 pb-0 pl-2 pr-2"
              fluid
            >
              <div v-if="viewButtons">
                <!-- Botoes de acoes -->
                <b-row v-if="showNovaPropostaButtons">
                  <b-col md="12 mb-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      block
                      @click="newProposal(row.id_visita)"
                    >
                      {{ $t('Nova Proposta') }}
                    </b-button>
                  </b-col>
                </b-row>

                <!-- Botoes de acoes -->
                <b-row v-if="showVerPropostaButtons">
                  <b-col md="12 mb-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      block
                      @click.stop.prevent="proposalDetail(row.id_proposta);"
                    >
                      {{ $t('Ver Proposta') }}
                    </b-button>
                  </b-col>
                </b-row>

                <!-- Botoes de acoes -->
                <b-row v-if="showEditarReport">
                  <b-col md="12 mb-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      block
                      @click.stop.prevent="getModalReport('load'); $emit('close-left-sidebar');"
                    >
                      {{ $t('Editar relatório') }}
                    </b-button>
                  </b-col>
                </b-row>

                <!-- Botoes de acoes -->
                <b-row v-if="showRejeitarButtons">
                  <b-col md="12 mb-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      block
                    >
                      {{ $t('Rejeitar Proposta') }}
                    </b-button>
                  </b-col>
                </b-row>

                <!-- Botoes de acoes -->
                <b-row v-if="showRejeitarVisitaButtons">
                  <b-col md="12 mb-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      block
                      @click.stop.prevent="rejeitarVisita();$emit('close-left-sidebar');"
                    >
                      {{ $t('Rejeitar Visita') }}
                    </b-button>
                  </b-col>
                </b-row>

                <!-- Botoes de acoes -->
                <b-row v-if="showActionsButtons">
                  <b-col md="12 mb-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      block
                      @click.stop.prevent="getModalReport(); $emit('close-left-sidebar');"
                    >
                      {{ $t('Concluir com relatório') }}
                    </b-button>
                  </b-col>
                  <b-col md="12 mb-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      block
                      @click.stop.prevent="modalConfirmConcluirSemRelatorio(); $emit('close-left-sidebar');"
                    >
                      {{ $t('Concluir sem relatório') }}
                    </b-button>
                  </b-col>
                </b-row>

                <b-row v-if="showActionsButtons">
                  <b-col md="12">
                    <app-collapse
                      type="margin"
                      class="mb-2 border-more-actions"
                    >
                      <app-collapse-item
                        :title="$t('Mais ações')"
                      >

                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          block
                          @click.stop.prevent="editModalData()"
                        >
                          {{ $t('Editar') }}
                        </b-button>

                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          block
                          @click.stop.prevent="modalConfirmCancelarVisita(); $emit('close-left-sidebar');"
                        >
                          {{ $t('Visita cancelada') }}
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                          variant="outline-secondary"
                          block
                          @click.stop.prevent="modalConfirmEliminarVisita(); $emit('close-left-sidebar');"
                        >
                          {{ $t('Eliminar') }}
                        </b-button>

                      </app-collapse-item>
                    </app-collapse>

                  </b-col>
                </b-row>
              </div>

              <template-basic-detail
                v-if="showTemplatebasicDetail"
                :row="modalData.data.sw070"
              />

              <template-external-detail
                v-if="showTemplateExternalDetail"
                :row="modalData.data.sw070"
              />

              <template-report-detail
                v-if="showTemplateReportDetail"
                :row="modalData.data.sw070"
                :row73="modalData.data.sw073"
              />

            </b-container>

          </vue-perfect-scrollbar>
          <div
            class="d-flex p-1 justify-content-center"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click.stop.prevent="hide"
            >
              {{ $t('Fechar') }}
            </b-button>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BContainer, BRow, BCol, VBTooltip, BButton, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { showModalDeleteMessage } from '@core/utils/utils'
import { configSettingsScroll } from '@core-custom/utils/ui'
import checkNested from 'check-nested'
import templateBasicDetail from './basicDetail.vue'
import templateExternalDetail from './externalDetail.vue'
import templateReportDetail from './reportDetail.vue'

export default {
  components: {
    BSidebar,
    BContainer,
    BRow,
    BCol,
    BButton,
    VuePerfectScrollbar,
    AppCollapse,
    AppCollapseItem,
    templateBasicDetail,
    templateExternalDetail,
    templateReportDetail,
    BOverlay,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    row: {
      type: Object,
      required: true,
    },
    separador:
    {
      type: Number,
      required: true,
    },
    getModalReport:
    {
      type: Function,
      required: false,
      default: null,
    },
    viewButtons:
    {
      type: Boolean,
      required: false,
      default: true,
    },
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    showMsgSuccessRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      modalData: [],
      processingAjax: true,
      activeRefreshListview: false,
      showTemplatebasicDetail: false,
      showTemplateExternalDetail: false,
      showTemplateReportDetail: false,
      showActionsButtons: false,
      showNovaPropostaButtons: false,
      showVerPropostaButtons: false,
      showRejeitarButtons: false,
      showEditarReport: false,
      showRejeitarVisitaButtons: false,
    }
  },
  mounted() {
    this.getModalData()
  },
  methods: {
    closeModal(val) {
      this.$parent.showProcessoModalDetail = val
    },
    getModalData() {
      this.processingAjax = true
      this.$store.dispatch('crm_visitas_listview/getInfoVisita', { sw070s01: this.row.id_visita, idSeparador: this.separador }).then(res => {
        this.modalData = res || []

        if (this.modalData.action === 1 && this.modalData.concluida === '0') {
          this.showActionsButtons = true
        }

        if (this.modalData.action === 1 && this.modalData.concluida === '1' && this.modalData.proposta === null) {
          this.showNovaPropostaButtons = true
          const oCurrentUser = this.$store.getters['auth/currentUser']

          if (oCurrentUser !== null && oCurrentUser !== undefined && checkNested(oCurrentUser, 'extras.basic') && oCurrentUser.extras.basic === 1) {
            this.showNovaPropostaButtons = true
          }

          if (oCurrentUser !== null && oCurrentUser !== undefined && 'extras' in oCurrentUser && 'perm' in oCurrentUser.extras
            && (Object.values(oCurrentUser.extras.perm).includes('pode_marcar_visitas_leads') || Object.values(oCurrentUser.extras.perm).includes('pode_marcar_visitas_leads_edita') || Object.values(oCurrentUser.extras.perm).includes('marca_visitas_outros_membros'))) {
            this.showNovaPropostaButtons = true
          }
        }

        if (this.modalData.concluida === '1' && this.modalData.proposta !== null && this.modalData.proposta !== -1) {
          this.showVerPropostaButtons = true
        }

        if (this.modalData.rejeitar === 1) {
          this.showRejeitarVisitaButtons = true
        }

        if ((this.modalData.data.sw073s02 !== undefined && this.modalData.data.sw073s02 !== null && (this.modalData.action === 1 || this.modalData.action === 4)) && !this.$store.hasModule('crm_proposals_form')) {
          this.showEditarReport = true
        }

        if (this.separador === 2 && this.modalData.externaVisita === 0) {
          this.showTemplatebasicDetail = true
        }

        if (this.separador === 2 && this.modalData.externaVisita === 1) {
          this.showTemplateExternalDetail = true
        }

        if (this.separador === 1 && this.modalData.externaVisita === 0) {
          this.showTemplatebasicDetail = true
        }

        if (this.separador === 1 && this.modalData.externaVisita === 1) {
          this.showTemplateExternalDetail = true
        }

        if (this.separador === 4 && this.modalData.externaVisita === 0) {
          this.showTemplatebasicDetail = true
        }

        if (this.separador === 4 && this.modalData.externaVisita === 1) {
          this.showTemplateExternalDetail = true
        }

        if (this.separador === 3) {
          this.showTemplateReportDetail = true
        }

        this.processingAjax = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    modalConfirmCancelarVisita() {
      this.$parent.getCancelModal()
    },
    async modalConfirmEliminarVisita() {
      const actionModal = new Promise(resolve => {
        showModalDeleteMessage(resolve, `${this.$t('Tem a certeza que pretende eliminar esta visita? Esta ação é irreversível!')}`)
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          this.processingAjax = true
          this.$store.dispatch('crm_visitas_listview/eliminarVisita').then(res => {
            if (res.error === 1) {
              this.showMsgErrorRequest(new Error(res.msg))
            } else {
              this.showMsgSuccessRequest({ message: res.msg })
              this.closeModal(false)
              this.$store.dispatch('crm_visitas_listview/fetchListView').catch(error => {
                this.showMsgErrorRequest(error)
              })
            }
            this.processingAjax = false
          }).catch(error => {
            this.showMsgErrorRequest(error)
          })
        } else {
          this.processingAjax = false
        }
      })
    },

    async modalConfirmConcluirSemRelatorio() {
      return new Promise(resolve => {
        this.$swal({
          title: '',
          html: `${this.$t('Pretende concluir a visita sem relatório?')}`,
          icon: 'question',
          showCancelButton: true,
          reverseButtons: true,
          confirmButtonText: this.$t('Sim'),
          cancelButtonText: this.$t('Não'),
          customClass: {
            confirmButton: 'btn btn-primary ml-sm-1 btn-xs-block',
            cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            this.processingAjax = true
            this.$store.dispatch('crm_visitas_listview/concluirSemRelatorio').then(res => {
              if (res.error === 1) {
                this.showMsgErrorRequest(new Error(res.msg))
              } else {
                this.showMsgSuccessRequest({ message: res.msg })
                this.closeModal(false)
                this.$store.dispatch('crm_visitas_listview/fetchListView').catch(error => {
                  this.showMsgErrorRequest(error)
                })
              }
              this.processingAjax = false
            }).catch(error => {
              this.showMsgErrorRequest(error)
            })
          }
          this.processingAjax = false
          resolve(true)
        })
      })
    },

    async newProposal(idVisit) {
      if (this.$store.hasModule('crm_proposals_form')) {
        await this.$store.dispatch('crm_proposals_form/getDataForm', idVisit).then(result => {
          if (result === true) {
            this.$store.dispatch('crm_proposals/setShowForm', true).catch(error => {
              this.showMsgErrorRequest(error)
            })
            this.closeModal(false)
          }
        }).catch(error => {
          this.showMsgErrorRequest(error)
        })
      } else {
        this.$router.push({ name: 'crm-proposals-new-visita', params: { idvisita: idVisit } })
      }
    },
    proposalDetail(idProposta) {
      this.$router.push({ name: 'crm-proposals-view-visita', params: { idproposta: idProposta } })
    },
    async editModalData() {
      this.processingAjax = false
      await this.$store.dispatch('crm_visitas_form/getDataVisita', { sw070s01: this.row.id_visita, externa: this.modalData.externaVisita }).then(res => {
        if (res !== null) {
          if (this.modalData.externaVisita !== null && this.modalData.data.sw070 !== null && this.modalData.data.sw070.sw070s29 === '1') {
            this.$emit('edit-modal-visita-lead-externa')
          } else if (this.modalData.externaVisita !== null && this.modalData.data.sw070 !== null && this.modalData.data.sw070.sw070s29 === '2') {
            this.$emit('edit-modal-visita-angariacao-externa')
          } else {
            this.$emit('edit-modal-visita')
          }
          this.closeModal(false)
        }
        this.processingAjax = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    rejeitarVisita() {
      this.$parent.getRejeitarModal()
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>
