<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Angariação')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw012v02'] }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-if="row['sw070s29'] === null || row['sw070s29'] === 2"
          :label="$tc('Lead', 1)"
        ><p
          class="text-primary mb-0"
        >
          {{ row['nomelead'] || '----' }}
        </p>

        </b-form-group>
        <b-form-group
          v-if="row['sw070s29'] !== null && row['sw070s29'] === '1'"
          :label="$tc('Lead', 1)"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s26'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-if="row['sw070s29'] !== null && row['sw070s29'] === '1'"
          :label="$t('Agente externo')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['nomeAgenteExterno'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Apreciação')"
        ><p
           class="text-primary mb-0"
         >
           <b-row>
             <b-col sm="12">
               {{ $t('Qualidade de construção') }}
             </b-col>
             <b-col sm="12">
               <b-badge :variant="row['txt_sw073s03_class']">
                 {{ row['txt_sw073s03'] }}
               </b-badge>
             </b-col>
           </b-row>
         </p>
          <p
            class="text-primary mb-0"
          >
            <b-row>
              <b-col md="12 mt-25">
                {{ $t('Acabamentos') }}
              </b-col>
              <b-col md="12">
                <b-badge :variant="row['txt_sw073s04_class']">
                  {{ row['txt_sw073s04'] }}
                </b-badge>
              </b-col>
            </b-row>
          </p>
          <p
            class="text-primary mb-0"
          >
            <b-row>
              <b-col md="12 mt-25">
                {{ $t('Exposição solar') }}
              </b-col>
              <b-col md="12">
                <b-badge :variant="row['txt_sw073s05_class']">
                  {{ row['txt_sw073s05'] }}
                </b-badge>
              </b-col>
            </b-row>
          </p>
          <p
            class="text-primary mb-0"
          >
            <b-row>
              <b-col md="12 mt-25">
                {{ $t('Localização') }}
              </b-col>
              <b-col md="12">
                <b-badge :variant="row['txt_sw073s06_class']">
                  {{ row['txt_sw073s06'] }}
                </b-badge>
              </b-col>
            </b-row>
          </p>
          <p
            class="text-primary mb-0"
          >
            <b-row>
              <b-col md="12 mt-25">
                {{ $t('Valor') }}
              </b-col>
              <b-col md="12">
                <b-badge :variant="row['txt_sw073s07_class']">
                  {{ row['txt_sw073s07'] }}
                </b-badge>
              </b-col>
            </b-row>
          </p>
          <p
            class="text-primary mb-0"
          >
            <b-row>
              <b-col md="12 mt-25">
                {{ $t('Apreciação global') }}
              </b-col>
              <b-col md="12">
                <b-badge :variant="row['txt_sw073s08_class']">
                  {{ row['txt_sw073s08'] }}
                </b-badge>
              </b-col>
            </b-row>
          </p>
        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('O que mais gostou:')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['txt_sw073s09'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('O que menos gostou:')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['txt_sw073s10'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Compraria este imóvel?')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['txt_sw073s11'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Porquê?')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['txt_sw073s12'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Data da visita')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s10'].substring(0,16) || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col
        v-if="row['sw070s05']"
        md="12"
      >
        <b-form-group
          :label="$t('Data de conclusão')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s05'].substring(0,16) || '----' }}
        </p>

        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, VBTooltip, BFormGroup, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BBadge,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    row: {
      type: Object,
      required: true,
    },
    row73: {
      type: Object,
      required: true,
    },
  },
  mounted() {
  },
  methods: {
  },
}
</script>
