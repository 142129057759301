<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-form-group
          :label="$t('Angariação')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw012v02'] }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$tc('Lead', 1)"
        ><p
          class="text-primary mb-0"
        >
          {{ row['nomelead'] || row['sw025s66'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Data da visita')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s10'].substring(0,16) || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('data de registo')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s03'].substring(0,16) || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Observações')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s12'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col
        v-if="row['sw070s44']"
        md="12"
      >
        <b-form-group
          :label="$t('Motivo de cancelamento')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s44'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, VBTooltip, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  mounted() {
  },
  methods: {
  },
}
</script>
