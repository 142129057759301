<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-form-group
          v-if="row['nomelead']"
          :label="$tc('Lead', 1)"
        ><p
          class="text-primary mb-0"
        >
          {{ row['nomelead'] }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Rede imobiliária')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['txt_sw070s21'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Agência')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['txt_sw070s41'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Nome agente')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['info_sw070s46']['nome'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Email agente')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['info_sw070s46']['email'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Telefone agente')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['info_sw070s46']['contacto'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-if="row['sw070s29'] == 1"
          :label="$t('Nome cliente')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s26'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-if="row['sw070s29'] == 1"
          :label="$t('Email cliente')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s27'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          v-if="row['sw070s29'] == 1"
          :label="$t('Telefone cliente')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s28'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Angariação')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw012v02'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Data da visita')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s10'].substring(0,16) || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Data de registo')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s03'].substring(0,16) || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col md="12">
        <b-form-group
          :label="$t('Observação')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s12'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
      <b-col
        v-if="row['sw070s44']"
        md="12"
      >
        <b-form-group
          :label="$t('Motivo de cancelamento')"
        ><p
          class="text-primary mb-0"
        >
          {{ row['sw070s44'] || '----' }}
        </p>

        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, VBTooltip, BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { sizeIconsAction } from '@core-custom/mixins/geral'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [sizeIconsAction],
  props: {
    row: {
      type: Object,
      required: true,
    },
  },
  mounted() {
  },
  methods: {
  },
}
</script>
